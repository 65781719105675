import React, { forwardRef } from 'react';

import useTextInput from '@/core/hooks/inputs/useTextInput';
import isNullish from '@/core/lib/utils/isNullish';
import type { NewInputProps } from '@/core/types/components';

export interface SearchInputProps extends NewInputProps<string> {
  type?: 'text';
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, forwardedRef) => {
  const {
    isFocused,
    isError,
    isSuccess,
    isCleanable,
    isDisabled,
    props: { type = 'text' },
    ...attrs
  } = useTextInput<SearchInputProps>({ forwardedRef, ...props });

  const isFocusedClasses = isFocused ? 'border border-stroke-button-primary-default' : '';
  const isErrorClasses = isError ? '' : '';
  const hasRightIconClasses = isCleanable || isSuccess ? '' : '';
  const isDisabledClasses = isDisabled ? '' : '';

  const hasValue = !isNullish(attrs.value) ? 'text-content-button-ghost-default' : '';

  return (
    <input
      className={`text-body-secondary relative max-h-10 w-full rounded-full bg-bg-pale px-3 py-[10px] text-content-primary outline-none placeholder:text-[#74777F] ${hasValue} ${isFocusedClasses} ${isErrorClasses} ${hasRightIconClasses} ${isDisabledClasses}`}
      {...attrs}
      type={type}
      inputMode={type}
    />
  );
});

export default SearchInput;
