import * as yup from 'yup';

import type { AnySearchParams, CommonSearchParams, UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute } from '@/core/lib/router/route';

const favoriteParams = yup.object({
  defaultTab: yup.string().oneOf(['searches', 'donations']).optional(),
});
type FavoriteParams = yup.InferType<typeof favoriteParams>;

class FavoritesRoute extends AbstractRoute {
  private params: FavoriteParams;
  static path = '/favoris';

  constructor(params?: FavoriteParams, common?: CommonSearchParams) {
    super(common);
    this.params = params || {};
  }

  getPath() {
    return FavoritesRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn<yup.AnyObjectSchema, typeof favoriteParams> {
    const { common, query } = AbstractRoute.useCommonParams();
    const search = favoriteParams.cast(query, { stripUnknown: true, assert: false });

    return { page: {}, search, common };
  }

  static init(url: URL): FavoritesRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new FavoritesRoute({}, common);
  }

  isPrivate() {
    return FavoritesRoute.isPrivate;
  }

  resolve() {
    return `/favoris${this.computeParams(this.params as AnySearchParams)}`;
  }

  clone(common: CommonSearchParams = {}): FavoritesRoute {
    const clone = FavoritesRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

export default { FavoritesRoute };
