import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Alert from '@/core/components/Alert';
import Header from '@/core/components/Header';
import Icons from '@/core/components/Icons';
import { useComponentsContext } from '@/core/lib/components/components.context';
import Store from '@/core/lib/new-architecture/store';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';
import style from '@/core/styles/Markdown.module.css';

interface CGUProps {
  back?: () => void;
}

const CGU: PageWithLayoutsAndProviders<CGUProps> = ({ back: propsBack }) => {
  const { t } = useTranslationContext(['common', 'cgu']);
  const { back } = useRouterContext();
  const { Page, Content } = useComponentsContext();

  const onBack = () => back(new Routes.CatalogueRoute({}));

  const { data, isLoading, errors } = Store.documents.useCgu();

  const cgu = data?.getContent();

  return (
    <Page>
      <Content>
        <Header content={t('nav-content', { ns: 'cgu' })} iconBefore="arrow-left" onBefore={propsBack ?? onBack} />
        <div className="w-inherit bg-bg-primary px-4">
          {isLoading && (
            <div aria-label={t('spinner.aria-valuetext', { ns: 'cgu' })} className="flex h-screen items-center justify-center">
              <Icons icon="spinner" size="40" color="button-default" />
            </div>
          )}
          {cgu && (
            <Markdown className={style.reactMarkDown} rehypePlugins={[rehypeRaw]}>
              {cgu}
            </Markdown>
          )}

          {errors && (
            <Alert status="error">
              {errors.message} {errors.kind}
            </Alert>
          )}
        </div>
      </Content>
    </Page>
  );
};

export default CGU;
